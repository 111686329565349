<template lang="pug">
  .rate-plan-settings
    AppNestedRoutesTabs(
      :routes="routes"
      :default-prevented="isDirty"
    )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import priceTableModule from "@/config/store/company_system/rate_plan_settings/price_table"
  import ratePlanModule from "@/config/store/company_system/rate_plan_settings/rate_plan/basic_settings"
  import planCommonModule from "@/config/store/company_system/rate_plan_settings/rate_plan/common_settings"
  import priceSettingsModule from "@/config/store/company_system/rate_plan_settings/rate_plan/prices_settings"

  const priceTableMixin = withStoreModule(priceTableModule, {
    name: "priceTables",
    readers: { isPriceTableDirty: "isDirty" }
  })

  const ratePlanMixin = withStoreModule(ratePlanModule, {
    name: "ratePlan",
    readers: { isPlanDirty: "isDirty" }
  })

  const planCommonSettingMixin = withStoreModule(planCommonModule, {
    name: "companiesPlanCommonSetting",
    readers: { isCommonSettingDirty: "isDirty" }
  })

  const pricesSettingMixin = withStoreModule(priceSettingsModule, {
    name: "planPricesSettingSetting",
    readers: { isPriceSettingDirty: "isDirty" }
  })

  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    mixins: [priceTableMixin, ratePlanMixin, planCommonSettingMixin, pricesSettingMixin],

    computed: {
      isDirty({ isPriceTableDirty, isPlanDirty, isCommonSettingDirty, isPriceSettingDirty }) {
        return isPriceTableDirty || isPlanDirty || isCommonSettingDirty || isPriceSettingDirty
      },

      routes() {
        return [
          {
            name: "RatePlan",
            title: this.$t("header.title.rate_plan")
          },
          {
            name: "PriceTable",
            title: this.$t("header.title.price_table")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
