import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { extractCommonMutations, buildCommonState } from "../../shared"
import extractActions from "./actions"

const baseURI = "/companies/price_tables"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withSorting: true,
  withPagination: true
})

store.mergeState(buildCommonState())

store.mergeMutations(extractCommonMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
